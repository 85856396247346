import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';

const MenuContainer = styled.div`
  width: 100%;
`;

const Option = styled.div<{ $isActive: boolean }>`
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: ${({ $isActive }) => `${$isActive ? 'var(--color-primary)' : 'var(--color-grey)'}`};
  padding: 20px;

  span {
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    color: var(--color-white);
    white-space: nowrap;
  }

  svg {
    color: var(--color-white);
  }
`;

interface IMenuProps {
  options: { Icon?: React.ReactNode; label: string }[];
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}

export default function Menu({ options, activeTab, setActiveTab }: IMenuProps) {
  const getBorderRadius = (index: number) => {
    if (index === 0) return 'var(--default-br) var(--default-br) 0 0';
    if (index === options.length - 1) return '0 0 var(--default-br) var(--default-br)';
    return 'none';
  };

  return (
    <MenuContainer>
      {options.map((option, index) => (
        <Option
          key={index}
          style={{ borderRadius: getBorderRadius(index) }}
          onClick={() => setActiveTab(index)}
          $isActive={activeTab === index}
        >
          {option.Icon && option.Icon}
          <span>{option.label}</span>
        </Option>
      ))}
    </MenuContainer>
  );
}
