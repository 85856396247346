import PrimaryButton from '@/components/Buttons/PrimaryButton';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import SecondBox from './SecondBox';
import { SectionContentContainer } from '@/styles/GlobalStyles';
import { Link } from 'react-router-dom';

const Section1Container = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  span {
    text-transform: uppercase;
    font-weight: 800;
  }

  p {
    color: var(--color-secondary);
    font-size: 18px;
    font-weight: 600;
  }

  @media (max-width: 1000px) {
    flex-wrap: wrap;
    justify-content: center;

    span,
    p {
      text-align: center;
    }
  }
`;

const FirstBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;

  @media (max-width: 1000px) {
    align-items: center;
  }
`;

const Catchphrase = styled.div`
  display: flex;
  flex-direction: column;
`;

const LessGambling = styled.span`
  font-size: 50px;
  color: var(--color-primary);

  @media (max-width: 1000px) {
    font-size: 35px;
  }
`;

const MoreFinance = styled.span`
  font-size: 75px;
  color: var(--color-accent);

  @media (max-width: 1000px) {
    font-size: 50px;
  }
`;

export type TMousePosition = { x: number; y: number };

export default function Section1() {
  const [mousePosition, setMousePosition] = useState<TMousePosition>({ x: 0, y: 0 });

  const handleMouseMoving = ({ x, y }: { x: number; y: number }) => {
    setMousePosition({ x: x / 100, y: y / 100 });
  };

  return (
    <div onMouseMove={(e) => handleMouseMoving({ x: e.screenX, y: e.screenY })}>
      <SectionContentContainer>
        <Section1Container>
          <FirstBox>
            <Catchphrase>
              <LessGambling>Less Gambling,</LessGambling>
              <MoreFinance>More Finance</MoreFinance>
            </Catchphrase>

            <p>Have greater financial control in a decentralized and secure way.</p>

            <div></div>

            <Link to={'/lock'}>
              <PrimaryButton
                customStyle={{ width: 'fit-content', padding: '0 40px' }}
                label="LOCK CRYPTO"
                color="var(--color-accent)"
              />
            </Link>
          </FirstBox>

          <div style={{ width: '50%' }}>
            <SecondBox mousePosition={mousePosition} />
          </div>
        </Section1Container>
      </SectionContentContainer>
    </div>
  );
}
