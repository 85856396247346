import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { ScreenConfigProvider } from './contexts/ScreenConfigContext';
import { WalletContextProvider } from './contexts/WalletContext';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import reduxStore from './redux';

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <Provider store={reduxStore}>
    <BrowserRouter>
      <WalletContextProvider>
        <App />
      </WalletContextProvider>
    </BrowserRouter>
  </Provider>,
);
