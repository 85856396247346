import React from 'react';
import { useWalletContext } from '@/contexts/WalletContext';
import { ethers } from 'ethers';
import { CONTRACT_ABI } from '@/config/SmartContract/ContractConfig';
import { toast } from 'react-toastify';

export default function useLockMainValue() {
  const { metamaskSDKProvider, activeNetwork, activeCurrency, setActiveCurrencyBalance, refreshWalletData } =
    useWalletContext();

  const lockMainValue = async (unlockAmountInSeconds: number, amount: number) => {
    try {
      if (!metamaskSDKProvider || !activeNetwork || !activeCurrency) {
        throw new Error('Provider not found');
      }

      if (!activeNetwork.contractAddress) {
        toast.error('Contract not added in this network');
        return;
      }

      if (activeCurrency.address) {
        toast.error('You should lock the main currency of the network');
        return;
      }

      const provider = new ethers.BrowserProvider(metamaskSDKProvider);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(activeNetwork.contractAddress, CONTRACT_ABI, signer);

      const tx = await contract.lockValue(unlockAmountInSeconds, {
        value: BigInt(ethers.parseEther(amount.toString())),
      });
      await tx.wait();

      refreshWalletData && (await refreshWalletData());
      setActiveCurrencyBalance && setActiveCurrencyBalance((prev) => (prev ? prev - amount : prev));
    } catch (err: any) {
      console.log(err);
      if (err.code === 4001) {
        toast.error('Transaction Cancelled');
        return;
      }
      toast.error('Transaction Failed');
    }
  };

  return { lockMainValue };
}
