import React from 'react';

import { SectionContentContainer } from '@/styles/GlobalStyles';
import Section1 from '@/components/HomeSections/Section1';
import Section2 from '@/components/HomeSections/Section2';
import { ScreenConfigProvider } from '@/contexts/ScreenConfigContext';
import Section3 from '@/components/HomeSections/Section3';
import Section4 from '@/components/HomeSections/Section4';

export default function Home() {
  return (
    <>
      <Section1 />

      <ScreenConfigProvider>
        <Section2 />
      </ScreenConfigProvider>

      <Section3 />

      <Section4 />
    </>
  );
}
