import React from 'react';
import { useWalletContext } from '@/contexts/WalletContext';
import { ethers } from 'ethers';
import { CONTRACT_ABI } from '@/config/SmartContract/ContractConfig';
import { toast } from 'react-toastify';

export default function useReleaseFunds() {
  const { metamaskSDKProvider, activeNetwork, refreshWalletData } = useWalletContext();

  const releaseFunds = async (
    { lockId }: { lockId: number },
    setIsClaiming: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    try {
      if (!metamaskSDKProvider || !activeNetwork) {
        throw new Error('Provider not found');
      }

      if (!activeNetwork.contractAddress) {
        toast.error('Contract not added in this network');
        return;
      }

      setIsClaiming(true);

      const provider = new ethers.BrowserProvider(metamaskSDKProvider);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(activeNetwork.contractAddress, CONTRACT_ABI, signer);

      const tx = await contract.releaseFundsById(lockId);
      await tx.wait();

      refreshWalletData && (await refreshWalletData());
      setIsClaiming(false);
    } catch (err) {
      console.log(err);
      setIsClaiming(false);
    }
  };

  return { releaseFunds };
}
