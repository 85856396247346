const parseBoolean = (value: string) => {
  const normalizedValue = value.toUpperCase();
  if (normalizedValue === 'TRUE') return true;
  if (normalizedValue === 'FALSE') return false;
  return undefined;
};

const ENVIRONMENT = {
  MODE: process.env.REACT_APP_MODE
    ? process.env.REACT_APP_MODE.toUpperCase() || 'DEVELOPMENT'
    : ('DEVELOPMENT' as 'DEVELOPMENT' | 'PRODUCTION'),
  HTTPS: process.env.REACT_APP_HTTPS ? parseBoolean(process.env.REACT_APP_HTTPS) : false,
  DOMAIN: process.env.REACT_APP_DOMAIN || 'localhost',

  SERVER_PORT: process.env.REACT_APP_SERVER_PORT ? parseInt(process.env.REACT_APP_SERVER_PORT) : null,
  CLIENT_PORT: process.env.REACT_APP_CLIENT_PORT ? parseInt(process.env.REACT_APP_CLIENT_PORT) : null,

  SERVER_URL: process.env.REACT_APP_SERVER_URL || 'localhost',
  CLIENT_URL: process.env.REACT_APP_CLIENT_URL || 'localhost',
};

const validateEnv = () => {
  const errors: string[] = [];

  const validModes = ['DEVELOPMENT', 'PRODUCTION'];
  if (!validModes.includes(ENVIRONMENT.MODE)) {
    errors.push(`Invalid value for MODE. Expected 'DEVELOPMENT' or 'PRODUCTION', got '${ENVIRONMENT.MODE}'.`);
  }

  if (typeof ENVIRONMENT.HTTPS !== 'boolean') {
    errors.push(`Invalid value for HTTPS. Expected 'TRUE' or 'FALSE', got '${process.env.HTTPS}'.`);
  }

  if (ENVIRONMENT.SERVER_PORT !== null && (isNaN(ENVIRONMENT.SERVER_PORT) || ENVIRONMENT.SERVER_PORT <= 0)) {
    errors.push(`Invalid value for SERVER_PORT. Expected a positive integer, got '${process.env.SERVER_PORT}'.`);
  }

  if (ENVIRONMENT.CLIENT_PORT !== null && (isNaN(ENVIRONMENT.CLIENT_PORT) || ENVIRONMENT.CLIENT_PORT <= 0)) {
    errors.push(`Invalid value for CLIENT_PORT. Expected a positive integer, got '${process.env.CLIENT_PORT}'.`);
  }

  if (errors.length > 0) {
    throw new Error(`Configuration error:\n${errors.join('\n')}`);
  }
};

validateEnv();

export default ENVIRONMENT;
