import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from '../pages/Home';
import Lock from '@/pages/Lock';
import Goal from '@/pages/Goal';

export default function MyRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/lock" element={<Lock />} />

      <Route path="/goal" element={<Goal />} />
    </Routes>
  );
}
