import ENVIRONMENT from './ENVIRONMENT';

const PROTOCOL = ENVIRONMENT.HTTPS ? 'https://' : 'http://';

// SERVER
// https://serverdomain.com OU http://localhost
const SERVER_URL_WITH_PROTOCOL = `${PROTOCOL}${ENVIRONMENT.SERVER_URL}`;

// SERVER PORT
const SERVER_PORT = ENVIRONMENT.SERVER_PORT;

// https://serverdomain.com OU http://localhost:PORT
const SERVER_FULL_URL =
  ENVIRONMENT.MODE === 'DEVELOPMENT' ? `${SERVER_URL_WITH_PROTOCOL}:${SERVER_PORT}` : SERVER_URL_WITH_PROTOCOL;

// https://serverdomain.com/api OU http://localhost:PORT/api
export const API_BASE = '';
export const API_URL = `${SERVER_FULL_URL}${API_BASE}`;

// CLIENT
// https://clientdomain.com OU http://localhost
const CLIENT_URL_WITH_PROTOCOL = `${PROTOCOL}${ENVIRONMENT.CLIENT_URL}`;
// CLIENT PORT
const CLIENT_PORT = ENVIRONMENT.CLIENT_PORT;
// https://client.domain.com OU http://localhost:PORT
export const CLIENT_FULL_URL =
  ENVIRONMENT.MODE === 'PRODUCTION' ? CLIENT_URL_WITH_PROTOCOL : `${CLIENT_URL_WITH_PROTOCOL}:${CLIENT_PORT}`;

export const WS_PROTOCOL = ENVIRONMENT.HTTPS ? 'wss://' : 'ws://';
const WS_API_URL_WITH_PROTOCOl = `${WS_PROTOCOL}${ENVIRONMENT.SERVER_URL}:${
  ENVIRONMENT.MODE === 'PRODUCTION' ? '' : SERVER_PORT
}${API_BASE}`;

const ENDPOINTS = {
  WALLET: '/wallet',
};

const API_ENDPOINTS = {
  CRYPTOS_RATES: '/cryptosrates',
  WALLET: {
    WALLET_DATA: `${ENDPOINTS.WALLET}/data`,
    TOKEN_BALANCE: `${ENDPOINTS.WALLET}/tokenbalance`,
    TOKEN_EXISTANCE: `${ENDPOINTS.WALLET}/tokenexistance`,
  },
};

const URLS = {
  MAIN_URLS: {
    CLIENT_PORT,
    CLIENT_URL_WITH_PROTOCOL,
    SERVER_PORT,
    SERVER_URL_WITH_PROTOCOL,
    API_URL,
    CLIENT_FULL_URL,
    SERVER_FULL_URL,
    WS_API_URL_WITH_PROTOCOl,
  },
  ENDPOINTS: API_ENDPOINTS,
};

export default URLS;
