import { useWalletContext } from '@/contexts/WalletContext';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaLock } from 'react-icons/fa';
import BlurredLoadDiv from '../Generics/BlurredLoadDiv';
import PrimaryButton from '../Buttons/PrimaryButton';
import { CommonStyles } from '../Form/Input/CommonStyles';

const BalanceContainer = styled.div`
  width: fit-content !important;
  padding: 0.5rem 1rem;
  background-color: var(--color-primary);
  border-radius: var(--default-br);
  height: var(--elements-height);
  align-items: center;

  ${CommonStyles}
  flex-direction: row;
`;

export default function Balance() {
  const { walletData } = useWalletContext();

  return (
    <BalanceContainer>
      <FaLock size={12} color="var(--color-white)" />
      <span>
        {walletData?.locks.totalValueInUSD && `${walletData?.locks.totalValueInUSD.toFixed(2)}$`}

        {walletData === undefined && (
          <BlurredLoadDiv isLoading={walletData === undefined}>
            <>0.00</>
          </BlurredLoadDiv>
        )}
      </span>

      {/* <PrimaryButton
        label="Test"
        color="pink"
        attributes={{
          onClick: () =>
            addTransaction({
              amount: BigInt(1000000000000000000000),
              id: BigInt(11),
              isMainCurrency: false,
              token: '0x42069dE48741Db40aeF864F8764432bBccbD0B69',
              unlockTimestamp: 1223443,
            }),
        }}
      /> */}
    </BalanceContainer>
  );
}
