import URLS from '@/config/URLs';
import MyAxiosServiceInstance from '@/services/MyAxiosService';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

export interface ITransactionFormatted {
  id: number;
  amount: number;
  unlockTimestamp: number;
  startTimestamp: number;
  isMainCurrency: boolean;
  token: string;
  valueInUSDBefore: null | number;
  valueInUSDNow: null | number;
  lastUpdate: null | number;
}

export interface IGetMainCurrencyBalance {
  mainCurrencyBalance: { value: number; valueInUSD: undefined | number };
}

interface IGetLocks {
  locks: { transactions: ITransactionFormatted[]; totalValueInUSD: number };
}

export type TWalletData = IGetMainCurrencyBalance & IGetLocks;

export default function useGetWalletData() {
  const [walletData, setWalletData] = useState<undefined | null | TWalletData>(undefined);

  const fetchWalletData = async (payload: { chainId: string; walletAddress: string }) => {
    try {
      setWalletData(undefined);

      const fetchWalletData = await MyAxiosServiceInstance.request<TWalletData>({
        requestConfig: { url: URLS.ENDPOINTS.WALLET.WALLET_DATA, method: 'post', data: payload },
      });

      setWalletData(fetchWalletData.data);
    } catch (err: any) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const cleanWalletData = () => {
    setWalletData(undefined);
  };

  return { fetchWalletData, walletData, cleanWalletData };
}
