import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SmartTable from '@/components/Generics/SmartTable';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import { ethers } from 'ethers';
import { useWalletContext } from '@/contexts/WalletContext';

const LocksContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;

  h1 {
    opacity: 0.2;
  }
`;

interface ITransaction {
  id: string;
  amount: number;
  unlockTime: number;
}

export default function Goals() {
  return (
    <LocksContainer>
      <h1 style={{ textAlign: 'center' }}>SOON</h1>
    </LocksContainer>
  );
}
