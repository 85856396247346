import PrimaryButton from '@/components/Buttons/PrimaryButton';
import { useWalletContext } from '@/contexts/WalletContext';
import getCryptoIcon, { getNetworkIcon } from '@/utils/getCryptoIcon';
import React, { useState } from 'react';
import { styled } from 'styled-components';
import CurrencyModal from './CurrencyModal';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { NETWORKS } from '@/config/NETWORKS';
import CryptoIcon from '@/components/CryptoIcon';
import { CommonStyles } from '@/components/Form/Input/CommonStyles';

const NetworkAndCurrencyContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const BoxContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const LabelContainer = styled.div`
  ${CommonStyles}
`;

export default function NetworkAndCurrencySelect() {
  const { activeCurrency, activeNetwork } = useWalletContext();
  const [showCurrencyModal, setShowCurrencyModal] = useState(false);

  return (
    <NetworkAndCurrencyContainer>
      <BoxContainer>
        <LabelContainer>
          <span>Network</span>
        </LabelContainer>
        <PrimaryButton
          element={<CryptoIcon icon={activeNetwork?.icon} />}
          label={`${activeNetwork?.name}`}
          attributes={{ type: 'button', onClick: () => toast.info('Change the network on Metamask') }}
          color="var(--color-lightprimary)"
        />
      </BoxContainer>

      <BoxContainer>
        <LabelContainer>
          <span>Currency</span>
        </LabelContainer>
        <PrimaryButton
          element={<CryptoIcon icon={activeCurrency ? Object.values(activeCurrency)[0]?.icon : undefined} />}
          label={activeCurrency ? Object.values(activeCurrency)[0]?.symbol : ''}
          attributes={{ type: 'button', onClick: () => setShowCurrencyModal(true) }}
          color="var(--color-lightprimary)"
        />
      </BoxContainer>

      <CurrencyModal showModal={showCurrencyModal} setShowModal={setShowCurrencyModal} />
    </NetworkAndCurrencyContainer>
  );
}
