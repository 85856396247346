import getCryptoIcon from '@/utils/getCryptoIcon';
import React from 'react';

export default function CryptoIcon({ icon }: { icon?: string }) {
  return (
    <>
      {icon && <img width={20} height={20} src={icon} />}
      {!icon && <img width={20} height={20} src={getCryptoIcon({ mainCurrency: false })} />}
    </>
  );
}
