import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import MyRoutes from './routes';
import GlobalStyles, { Body } from './styles/GlobalStyles';
import ScrollToTop from './utils/ScrollToTop';
import Header from './components/Generics/Header';
import { MetaMaskProvider } from '@metamask/sdk-react';
import Footer from './components/Generics/Footer';
import { ScreenConfigProvider } from './contexts/ScreenConfigContext';

function App() {
  const [theme, setTheme] = useState('light');
  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };
  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
  }, [theme]);

  const { pathname } = useLocation();
  useEffect(() => {
    pathname === '/' && (document.title = 'GAMBLANCE - Less gambling, more finances!');
    pathname === '/lock' && (document.title = 'Lock | GAMBLANCE');
    pathname === '/goal' && (document.title = 'Goal | GAMBLANCE');
  }, [pathname]);

  return (
    <>
      <ScrollToTop />

      <MetaMaskProvider
        debug={false}
        sdkOptions={{
          dappMetadata: {
            name: 'Gamblance',
            url: window.location.href,
          },
          infuraAPIKey: process.env.REACT_APP_INFURA_API_KEY,
        }}
      >
        <ScreenConfigProvider>
          <Header />
        </ScreenConfigProvider>
      </MetaMaskProvider>

      <Body>
        <MyRoutes />

        <Footer />
      </Body>

      <GlobalStyles />
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        closeOnClick
        rtl={false}
        draggable={'mouse'}
        theme="light"
      />
    </>
  );
}

export default App;
