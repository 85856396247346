import { SectionContentContainer } from '@/styles/GlobalStyles';
import React, { useState } from 'react';
import styled from 'styled-components';
import Menu from './Menu';
import { useScreenConfig } from '@/contexts/ScreenConfigContext';
import { FaLock } from 'react-icons/fa6';
import { GoGoal } from 'react-icons/go';
import MenuMobile from './MenuMobile';
const box3Img = require('./assets/img.png');

const Section2Wrapper = styled.div`
  background-color: #e5e8ee;

  h2 {
    text-transform: none;
    text-align: center;
  }
`;

const Section2Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  margin-bottom: 80px;
  max-width: 1000px;
`;

const Boxes = styled.div<{ $width: number }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ $width }) => {
    if ($width > 1000) return '1fr 2fr 1fr';
    return '1fr';
  }};
  gap: ${({ $width }) => ($width > 1000 ? '5rem' : '2.5rem')};
`;

const Box1 = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const MenuTexts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  span {
    font-weight: 700;
    white-space: nowrap;
    font-size: 18px;
    color: var(--color-primary);
  }

  p {
    font-size: 18px;
    color: var(--color-primary);
  }
`;

export default function Section2() {
  const { width } = useScreenConfig();
  const [activeTab, setActiveTab] = useState<number>(0);

  return (
    <Section2Wrapper>
      <SectionContentContainer>
        <Section2Container>
          <Title>
            <h2>Control your finances in an intuitive and decentralized way</h2>
          </Title>

          <Boxes $width={width}>
            <Box1>
              {width > 720 ? (
                <Menu
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  options={[
                    { Icon: <FaLock />, label: 'Lock Crypto' },
                    { Icon: <GoGoal />, label: 'Set a Goal' },
                  ]}
                />
              ) : (
                <MenuMobile
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  options={[
                    { Icon: <FaLock />, label: 'Lock' },
                    { Icon: <GoGoal />, label: 'Goal' },
                  ]}
                />
              )}
            </Box1>

            {width < 1000 && <Box1>{activeTab === 0 && <img height={300} src={box3Img} alt="" />}</Box1>}

            <Box1>
              {activeTab === 0 && (
                <MenuTexts>
                  <div>
                    <span>Wallet connection</span>
                    <p>You can connect through Metamask by click the "Connect Wallet" header button</p>
                  </div>

                  <div>
                    <span>Locking</span>
                    <p>
                      Go to the lock tab and specify your network and currency. In case your token is not listed on
                      Gamblance, you can still select it putting it’s contract address
                    </p>
                  </div>

                  <div>
                    <span>Lock configuration</span>
                    <p>Enter the amount of coins and the exact date of unlocking the tokens</p>
                  </div>

                  <div>
                    <span>Contract interaction</span>
                    <p>
                      Double check the configuration and click in the button "lock", approve the tokens if needed and
                      sign the transaction
                    </p>
                  </div>

                  <div>
                    <span>Claim</span>
                    <p>When the stipulated date arrives, your coins will be available for claim</p>
                  </div>
                </MenuTexts>
              )}

              {activeTab === 1 && <h3 style={{ color: 'var(--color-grey)' }}>Soon</h3>}
            </Box1>

            {width > 1000 && <Box1>{activeTab === 0 && <img height={300} src={box3Img} alt="" />}</Box1>}
          </Boxes>
        </Section2Container>
      </SectionContentContainer>
    </Section2Wrapper>
  );
}
