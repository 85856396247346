import React from 'react';
import styled from 'styled-components';
import { CommonStyles } from './CommonStyles';
import { combineSlices } from '@reduxjs/toolkit';
import { ISmartTableProps } from '.';
import LoadingGif from '@/components/Buttons/LoadingGif';
import { SmallInfoMessage } from '@/styles/GlobalStyles';

const TableWrapper = styled.div`
  width: 100%;
  height: fit-content;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const MockTableContainer = styled.div`
  width: 100%;
`;

const StyledTable = styled.table`
  ${CommonStyles}
  min-height: 500px;

  tbody {
    position: relative;
  }
`;

const NoDataContainer = styled.div`
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-primary);

  span {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 22px;
    opacity: 0.35;
    color: var(--color-white);
    white-space: normal;
    text-align: center;
  }
`;

const MockTable = ({ titles, data }: ISmartTableProps) => {
  let titlesFiltered = titles ? titles : [''];
  return <MockTableElement titles={titlesFiltered} data={data} />;
};

const MockTableElement = ({ titles, data }: { titles: string[]; data: ISmartTableProps['data'] }) => {
  const minimumItems = 10;

  const filteredData = (function () {
    if (!data || data.length === 0) return data;

    const itemsLeft = minimumItems - data.length;
    return [...data, ...Array(itemsLeft).fill('')];
  })();

  const trMaintanance = (
    <tr>
      <td>
        <NoDataContainer>
          <span>We are under maintenance.</span>
          <SmallInfoMessage>Don't worry, your funds are safe on the blockchain.</SmallInfoMessage>
        </NoDataContainer>
      </td>
    </tr>
  );

  const trLoading = (
    <tr>
      <td>
        <NoDataContainer>
          <LoadingGif />
        </NoDataContainer>
      </td>
    </tr>
  );

  const trNoTransactions = (
    <tr>
      <td>
        <NoDataContainer>
          <span>No transactions yet</span>
          <SmallInfoMessage>How about starting to take care of your financial future now?</SmallInfoMessage>
        </NoDataContainer>
      </td>
    </tr>
  );

  return (
    <TableWrapper>
      <MockTableContainer>
        <StyledTable>
          <colgroup>{titles && titles.map((_, index) => <col key={index} />)}</colgroup>

          <thead>
            <tr>
              {titles.map((title, index) => (
                <th key={index}>{title}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {filteredData &&
              filteredData.length > 0 &&
              filteredData.map((item, rowIndex) => (
                <tr key={rowIndex}>
                  {titles.map((_, colIndex) => (
                    <td key={colIndex}>{item[colIndex] || ''}</td>
                  ))}
                </tr>
              ))}

            {filteredData && filteredData.length === 0 && trNoTransactions}

            {filteredData === undefined && trLoading}
            {filteredData === null && trMaintanance}
          </tbody>
        </StyledTable>
      </MockTableContainer>
    </TableWrapper>
  );
};

export default MockTable;
