import React from 'react';
import styled from 'styled-components';
import { menuItems } from '.';
import { Link, useLocation } from 'react-router-dom';

const BottomMenuContainer = styled.div`
  user-select: none;
  height: var(--header-height);
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 10;
  width: 100%;
  background: var(--color-primary);
  transition: box-shadow 0.3s ease;

  border-top: 2px solid var(--color-secondary);
`;

const Items = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
`;

const Item = styled.div<{ $isActive: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  flex-direction: column;

  span {
    color: ${({ $isActive }) => ($isActive ? 'var(--color-accent)' : 'var(--color-white)')};
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }

  svg {
    color: ${({ $isActive }) => ($isActive ? 'var(--color-accent)' : 'var(--color-white)')};
    width: 14px !important;
    height: 14px !important;
  }
`;

export default function BottomMenu() {
  const {} = useLocation();

  return (
    <BottomMenuContainer>
      <Items>
        {Object.keys(menuItems).map((key) => (
          <Link to={menuItems[key].path}>
            <Item $isActive={location.pathname === menuItems[key].path}>
              {menuItems[key].icon}
              <span>{key}</span>
            </Item>
          </Link>
        ))}
      </Items>
    </BottomMenuContainer>
  );
}
