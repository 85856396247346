import React, { useState } from 'react';

import styled from 'styled-components';

import { FieldValues, UseFormGetValues, UseFormReturn } from 'react-hook-form';
import { CommonStyles } from './CommonStyles';

const DefaultInputContainer = styled.div`
  ${CommonStyles}
`;

const WrapperContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 1rem;
  background-color: rgb(0, 0, 0, 0.05);
`;

const IconContainer = styled.div`
  display: flex;

  svg {
    color: var(--color-white) !important;
  }
`;

const ErrorContainer = styled.div`
  height: 19px !important;
`;

const ErrorMessage = styled.span`
  font-size: 12px !important;
  color: var(--color-error) !important;
`;

export interface ICreateDefaultInput {
  id: string;
  label?: string;
  icon?: JSX.Element;
  rhfConfig: UseFormReturn<FieldValues, any, undefined>;
  validationFn: (value: any, getValues: UseFormGetValues<FieldValues>) => { valid: boolean; errorMsg: string };
  attributes: React.InputHTMLAttributes<HTMLInputElement>;
}

export default function DefaultInput(props: ICreateDefaultInput) {
  const { id, label, icon, attributes, rhfConfig, validationFn } = props;

  const {
    register,
    formState: { errors },
    getValues,
  } = rhfConfig;

  const [validationValue, setValidationValue] = useState({
    valid: false,
    errorMsg: '',
  });

  const validation = (value: any) => {
    const validate = validationFn(value, getValues);
    setValidationValue(validate);
    return validate;
  };

  const { ...registerProps } = register(id, {
    valueAsNumber: false,
    validate: (value: any) => {
      const { valid } = validation(value);
      return valid;
    },
  });

  const getErrorMessage = () => {
    if (errors[id]) {
      if (errors[id]!.type === 'required') {
        return 'Required field.';
      } else if (errors[id]!.type === 'validate') {
        return validationValue.errorMsg;
      }
    }
    return '';
  };

  const errorMessage = getErrorMessage();

  return (
    <DefaultInputContainer>
      {label && <span>{label}</span>}

      <WrapperContainer>
        {icon && <IconContainer>{icon}</IconContainer>}

        <label htmlFor={id}>
          <input id={id} {...attributes} {...registerProps} aria-invalid={errors[id] ? 'true' : 'false'} />
        </label>
      </WrapperContainer>

      {errorMessage && <ErrorContainer>{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}</ErrorContainer>}
    </DefaultInputContainer>
  );
}
