import React from 'react';
import { useWalletContext } from '@/contexts/WalletContext';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';

export default function useApproveTokens() {
  const { metamaskSDKProvider, activeNetwork, activeCurrency } = useWalletContext();

  const activeCurrencyInfo = activeCurrency ? Object.values(activeCurrency)[0] : undefined;

  const approveTokens = async (amount: number) => {
    try {
      if (!activeNetwork.contractAddress) {
        toast.error('Contract not added in this network');
        return;
      }

      if (!metamaskSDKProvider || !activeNetwork || !activeCurrency) {
        throw new Error('Provider not found');
      }

      if (!activeCurrencyInfo || !activeCurrencyInfo.address) {
        toast.error('Invalid token contract');
        return;
      }

      const provider = new ethers.BrowserProvider(metamaskSDKProvider);
      const signer = await provider.getSigner();
      const erc20Contract = new ethers.Contract(
        activeCurrencyInfo.address,
        [
          'function approve(address spender, uint256 amount) public returns (bool)',
          'function decimals() view returns (uint8)',
        ],
        signer,
      );

      const decimals = await erc20Contract.decimals();
      const formattedAmount = ethers.parseUnits(amount.toString(), decimals);

      const tx = await erc20Contract.approve(activeNetwork.contractAddress, formattedAmount);
      await tx.wait();

      toast.success('Tokens approved successfully!');
      return { formattedAmount };
    } catch (err: any) {
      if (err.code === 4001) {
        toast.error('Approval transaction cancelled');
        return;
      }
      toast.error('Approval transaction failed');
    }
  };

  return { approveTokens };
}
