import URLS from '@/config/URLs';
import { useWalletContext } from '@/contexts/WalletContext';
import MyAxiosServiceInstance from '@/services/MyAxiosService';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

export interface IGetTokenBalance {
  tokenBalance: { value: number; valueInUSD: undefined | number };
}

interface IFetchGetTokenBalancePayload {
  walletAddress: string;
  chainId: string;
  tokenAddress: string;
}

export default function useGetTokenBalance() {
  const [tokenBalance, setTokenBalance] = useState<undefined | null | IGetTokenBalance['tokenBalance']>(undefined);

  const fetchTokenBalance = async (payload: IFetchGetTokenBalancePayload) => {
    try {
      const fetch = await MyAxiosServiceInstance.request<IGetTokenBalance>({
        requestConfig: {
          url: URLS.ENDPOINTS.WALLET.TOKEN_BALANCE,
          method: 'post',
          data: payload,
        },
      });

      if (fetch.data) {
        setTokenBalance(fetch.data.tokenBalance);
      }
    } catch (err) {
      toast.error('Error getting token balance');
    }
  };

  return { fetchTokenBalance, tokenBalance };
}
