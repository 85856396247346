import React from 'react';
import { useWalletContext } from '@/contexts/WalletContext';
import { ethers } from 'ethers';
import { CONTRACT_ABI } from '@/config/SmartContract/ContractConfig';
import { toast } from 'react-toastify';

export default function useLockTokens() {
  const { metamaskSDKProvider, activeNetwork, activeCurrency, setActiveCurrencyBalance, refreshWalletData } =
    useWalletContext();

  const activeCurrencyInfo = activeCurrency ? Object.values(activeCurrency)[0] : undefined;

  const lockTokens = async (unlockAmountInSeconds: number, formattedAmount: bigint) => {
    try {
      if (!metamaskSDKProvider || !activeNetwork || !activeCurrencyInfo) {
        throw new Error('Provider not found');
      }

      if (!activeNetwork.contractAddress) {
        toast.error('Contract not added in this network');
        return;
      }

      if (!activeCurrencyInfo.address) {
        toast.error('Invalid token contract');
        return;
      }

      const provider = new ethers.BrowserProvider(metamaskSDKProvider);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(activeNetwork.contractAddress, CONTRACT_ABI, signer);

      const tx = await contract.lockTokens(activeCurrencyInfo.address, formattedAmount, unlockAmountInSeconds);
      await tx.wait();

      refreshWalletData && (await refreshWalletData());
    } catch (err: any) {
      if (err.code === 4001) {
        toast.error('Transaction Cancelled');
        return;
      }
      toast.error('Transaction Failed');
    }
  };

  return { lockTokens };
}
