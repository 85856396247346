import React, { useState } from 'react';
import styled from 'styled-components';
import LockBox from './LockBox';
import SmartTable from '@/components/Generics/SmartTable';
// import PrimaryButton from '@/components/Buttons/PrimaryButton';
// import useReleaseFunds from '@/hooks/useReleaseFunds';
// import getCryptoIcon from '@/utils/getCryptoIcon';
// import { useScreenConfig } from '@/contexts/ScreenConfigContext';
// import { toast } from 'react-toastify';
// import { useWalletContext } from '@/contexts/WalletContext';
// import { ITransactionFormatted } from '@/hooks/useGetWalletData';
import Balance from '@/components/Balance';
import LocksTable from './LocksTable';

const LocksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
`;

const ConfigAndTable = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;

  @media (max-width: 720px) {
    flex-wrap: wrap;
  }
`;

export default function Locks() {
  return (
    <LocksContainer>
      <ConfigAndTable>
        <LockBox />

        <LocksContainer>
          <Balance />
          <LocksTable />
        </LocksContainer>
      </ConfigAndTable>
    </LocksContainer>
  );
}
