import { useWalletContext } from '@/contexts/WalletContext';
import React from 'react';
import styled from 'styled-components';
import { MediumInfoMessage } from '@/styles/GlobalStyles';
import formatWallet from '@/utils/formatWallet';

const ConnectedWalletContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  p {
    font-weight: 600;
    text-transform: uppercase;
    color: var(--color-grey);
  }
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span {
    color: var(--color-white);
    font-size: 14px;
    font-weight: 700;
  }
`;

const LiveDot = styled.div<{ $color: string }>`
  display: flex;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: ${({ $color }) => $color};
  animation: pulseKey 1.5s infinite;

  @keyframes pulseKey {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 ${({ $color }) => $color};
    }
    70% {
      transform: scale(1.2);
      box-shadow: 0 0 10px 5px ${({ $color }) => $color}33;
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 ${({ $color }) => $color};
    }
  }
`;

export default function ConnectedWallet() {
  const { metamaskAccount } = useWalletContext();

  return (
    <ConnectedWalletContainer>
      <LabelContainer>
        <LiveDot $color={metamaskAccount ? 'var(--color-green)' : 'var(--color-red)'} />
        <span>{metamaskAccount ? 'Wallet Connected' : 'Wallet Disconnected'}</span>
      </LabelContainer>
    </ConnectedWalletContainer>
  );
}
