import PrimaryButton from '@/components/Buttons/PrimaryButton';
import { SectionContentContainer } from '@/styles/GlobalStyles';
import React from 'react';
import styled from 'styled-components';
import { FaDiscord } from 'react-icons/fa';
import FAQ from '@/components/Generics/FAQ';
import { useScreenConfig } from '@/contexts/ScreenConfigContext';

const Section4Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    text-transform: none;
  }
`;

const Box1 = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  p {
    max-width: 500px;
    font-weight: 500;
  }

  @media (max-width: 1000px) {
    align-items: center;
    width: 100%;

    h2,
    p {
      text-align: center;
    }
  }
`;

const Box2 = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  p {
    font-weight: 600;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export default function Section4() {
  const { width } = useScreenConfig();

  return (
    <SectionContentContainer>
      <Section4Container>
        <Box1>
          <Title>
            {width > 1000 && (
              <>
                <h2>Frequently</h2>
                <h2>Asked</h2>
                <h2>Questions</h2>
              </>
            )}

            {width <= 1000 && (
              <>
                <h2>F.A.Q</h2>
              </>
            )}
          </Title>

          <p>If you have any further questions, please contact us via our discord server.</p>

          <PrimaryButton
            customStyle={{ width: 'fit-content' }}
            color="#5662F6"
            label="Discord"
            element={<FaDiscord size={20} />}
          />
        </Box1>

        <Box2>
          <FAQ
            questions={[
              {
                question: `01 Are there any charges on the platform?`,
                answer: `The only fee charged by the platform is 2.5% at the time of signing the contract in the LOCK modality. With this fee, we can provide improvements and new features in addition to helping with the maintainability of services and security.`,
              },
              {
                question: `02 What networks and currencies does Gamblance support?`,
                answer: `Currently, our services support any cryptocurrency/altcoin/token that is within the Base, Arbitrum, and Polygon networks.`,
              },
              {
                question: `03 Who is our platform for?`,
                answer: `Our platform is designed for anyone who has difficulty saving money and managing their finances in general. Here, in a safer and more decentralized way, you can set your own limits and achieve your life goals.`,
              },
              {
                question: `04 How decentralized are the interactions?`,
                answer: `Absolutely every process related to the modalities is decentralized. At no time will your money pass through any individual, company or organization. It will interact with the contract only to enter and exit.`,
              },
              {
                question: `05 How secure are the contracts I will interact with?`,
                answer: `The contracts you will interact with follow fundamental security criteria such as Checks-Effects-Interactions standardization and implementation of modules to prevent reentrant attacks. The only thing you should pay attention to is the contract you are interacting with, whether it really belongs to our services or not.
More details here: JLenon.com`,
              },
            ]}
          />
        </Box2>
      </Section4Container>
    </SectionContentContainer>
  );
}
