import PrimaryButton from '@/components/Buttons/PrimaryButton';
import CryptoIcon from '@/components/CryptoIcon';
import Modal from '@/components/Generics/Modal';
import ModalContentTemplate1 from '@/components/Generics/Modal/ContentTemplate1';
import { ICurrency, INetwork } from '@/config/NETWORKS';
import { useWalletContext } from '@/contexts/WalletContext';
import useCheckTokenExistance from '@/hooks/useCheckTokenExistance';
import { SmallInfoMessage } from '@/styles/GlobalStyles';
import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { FaAngleRight } from 'react-icons/fa6';

import styled from 'styled-components';

const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const CurrenciesList = styled.div`
  background-color: var(--color-primary);
  margin: 0 calc(-1 * var(--default-pdn));
  max-height: 400px;
  overflow-y: scroll;
`;

const CurrencyInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 5px;
  justify-content: space-between;
`;

const CurrencySlugAndName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

interface ICurrencyModal {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CurrencyModal({ showModal, setShowModal }: ICurrencyModal) {
  const { fetchTokenExistance } = useCheckTokenExistance();
  const [isFetchingTokenExistance, setIsFetchingTokenExistance] = useState(false);
  const { activeNetwork, setActiveCurrency, metamaskAccount } = useWalletContext();

  const gamblanceCurrenciesList = activeNetwork.currenciesList;
  const gamblanceCurrenciesSymbols = Object.keys(gamblanceCurrenciesList);

  const [searchValue, setSearchValue] = useState('');

  const [currenciesFiltered, setCurrenciesFiltered] = useState<INetwork['currenciesList']>(gamblanceCurrenciesList);

  useEffect(() => {
    const searchValueFiltered = searchValue.trim().toLowerCase() || '';

    if (!searchValueFiltered || searchValueFiltered === '') {
      setCurrenciesFiltered(gamblanceCurrenciesList);
      return;
    }

    const _symbolsFiltered = gamblanceCurrenciesSymbols.filter((symbol) => {
      const gamblanceCurrency = gamblanceCurrenciesList[symbol];
      const tokenAddressFiltered = gamblanceCurrency.address?.toLowerCase();
      return (
        (tokenAddressFiltered && searchValueFiltered.includes(tokenAddressFiltered)) ||
        gamblanceCurrency.name.toLowerCase().includes(searchValueFiltered) ||
        gamblanceCurrency.slug.toLowerCase().includes(searchValueFiltered)
      );
    });

    let _currenciesFiltered = {};
    for (const symbol of _symbolsFiltered) {
      _currenciesFiltered = { ..._currenciesFiltered, [symbol]: gamblanceCurrenciesList[symbol] };
    }

    setCurrenciesFiltered(_currenciesFiltered);
  }, [gamblanceCurrenciesList, searchValue]);

  useEffect(() => {
    const getUnknownToken = async () => {
      setIsFetchingTokenExistance(true);

      if (searchValue.length > 30 && gamblanceCurrenciesList && setActiveCurrency) {
        const isAddressValid = ethers.isAddress(searchValue);
        const isListedAtGamblance = gamblanceCurrenciesSymbols.find(
          (symbol) => gamblanceCurrenciesList[symbol].address === searchValue,
        );

        if (isAddressValid && !isListedAtGamblance && metamaskAccount) {
          const fetch = await fetchTokenExistance({
            walletAddress: metamaskAccount,
            chainId: activeNetwork.chainId,
            tokenAddress: searchValue,
          });
          if (fetch && fetch.tokenExistance) {
            const { symbol } = fetch.tokenExistance.details;

            const tokenFound: ICurrency = { [symbol]: { slug: symbol, name: '', address: searchValue, symbol } };
            setCurrenciesFiltered(tokenFound);
          }
        }
      }
      setIsFetchingTokenExistance(false);
    };

    getUnknownToken();
  }, [searchValue]);

  const currenciesFilteredListElement = () => {
    const currenciesElements = Object.keys(currenciesFiltered).map((symbol, index) => {
      const currencyOnList = currenciesFiltered[symbol];
      return (
        <PrimaryButton
          attributes={{
            onClick: () => {
              setActiveCurrency && setActiveCurrency({ [symbol]: currencyOnList });
              setShowModal(false);
            },
          }}
          key={index}
          customStyle={{ height: 50, borderRadius: 0 }}
          element={
            <CurrencyInfo>
              <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                <CryptoIcon icon={currencyOnList?.icon} />

                <CurrencySlugAndName>
                  <span>{currencyOnList?.symbol}</span>
                  <SmallInfoMessage>{currencyOnList?.name}</SmallInfoMessage>
                </CurrencySlugAndName>
              </div>

              <FaAngleRight color="var(--color-accent)" />
            </CurrencyInfo>
          }
        />
      );
    });

    return (
      <CurrenciesList>
        {currenciesElements.length > 0 && currenciesElements}

        {currenciesElements.length === 0 && !isFetchingTokenExistance && (
          <CurrencyInfo style={{ justifyContent: 'center' }}>
            <h6>No Results</h6>
          </CurrencyInfo>
        )}

        {isFetchingTokenExistance && (
          <CurrencyInfo style={{ justifyContent: 'center' }}>
            <h6>Loading</h6>
          </CurrencyInfo>
        )}
      </CurrenciesList>
    );
  };

  return (
    <Modal showModal={showModal} setShowModal={setShowModal}>
      <ModalContentTemplate1 title={'Select a token'}>
        <WrapperContainer>
          <div>
            <input
              placeholder="Search name or paste address"
              type="text"
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>

          {currenciesFilteredListElement()}
        </WrapperContainer>
      </ModalContentTemplate1>
    </Modal>
  );
}
