import Goals from '@/components/Modality/Goals';
import { SectionContentContainer } from '@/styles/GlobalStyles';
import React from 'react';

export default function Goal() {
  return (
    <SectionContentContainer>
      <Goals />
    </SectionContentContainer>
  );
}
