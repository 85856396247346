import PrimaryButton from '@/components/Buttons/PrimaryButton';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

const DogeImg = require('./assets/DOGEicon2.png');
const EtherImg = require('./assets/ETHicon2.png');
const BitcoinImg = require('./assets/BTCicon2.png');

const BChain1Img = require('./assets/Bchain.png');
const BChain2Img = require('./assets/Bchain2.png');
const BChain3Img = require('./assets/Bchain3.png');
const BChain4Img = require('./assets/Bchain4.png');

const SecondBoxContainer = styled.div`
  scale: 1.1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 500px;

  @media (max-width: 1000px) {
    scale: 0.7;
    height: 400px;
  }
`;

type TMousePosition = { x: number; y: number };

const BChain1 = styled.div.attrs<{ $mousePosition: TMousePosition }>(({ $mousePosition }) => ({
  style: {
    display: 'flex',
    position: 'absolute',
    opacity: 1,
    transform: `translate(${60 + $mousePosition.x}px, ${100 + $mousePosition.y}px)`,
  },
}))``;

const BChain2 = styled.div.attrs<{ $mousePosition: TMousePosition }>(({ $mousePosition }) => ({
  style: {
    display: 'flex',
    position: 'absolute',
    opacity: 0.8,
    transform: `translate(${-85 - $mousePosition.x}px, ${0 - $mousePosition.y}px)`,
  },
}))``;

const BChain3 = styled.div.attrs<{ $mousePosition: TMousePosition }>(({ $mousePosition }) => ({
  style: {
    display: 'flex',
    position: 'absolute',
    opacity: 0.7,
    transform: `translate(${90 + $mousePosition.x}px, ${-120 + $mousePosition.y}px)`,
  },
}))``;

const BChain4 = styled.div.attrs<{ $mousePosition: TMousePosition }>(({ $mousePosition }) => ({
  style: {
    display: 'flex',
    position: 'absolute',
    opacity: 0.6,
    transform: `translate(${-35 - $mousePosition.x}px, ${-150 - $mousePosition.y}px)`,
  },
}))``;

const Doge = styled.div.attrs<{ $mousePosition: TMousePosition }>(({ $mousePosition }) => ({
  style: {
    position: 'absolute',
    transform: `rotate(-${$mousePosition.y * 5}deg)`,
    right: -4,
    top: -27,
    zIndex: 0,
  },
}))``;

const Ether = styled.div.attrs<{ $mousePosition: TMousePosition }>(({ $mousePosition }) => ({
  style: {
    position: 'absolute',
    transform: `rotate(${$mousePosition.y * 5 - 70}deg)`,
    right: -29,
    top: -9,
    zIndex: 0,
  },
}))``;

const Bitcoin = styled.div.attrs<{ $mousePosition: TMousePosition }>(({ $mousePosition }) => ({
  style: {
    position: 'absolute',
    transform: `rotate(${$mousePosition.y * 5 - 20}deg)`,
    right: 69,
    top: -24,
    zIndex: 0,
  },
}))``;

export default function SecondBox({ mousePosition }: { mousePosition: TMousePosition }) {
  const size = 5;

  return (
    <SecondBoxContainer>
      <BChain4 $mousePosition={mousePosition}>
        <img style={{ zIndex: 1 }} height={size * 12} src={BChain4Img} alt="" />
      </BChain4>

      <BChain3 $mousePosition={mousePosition}>
        <img style={{ zIndex: 1 }} height={size * 20} src={BChain3Img} alt="" />

        <Bitcoin $mousePosition={mousePosition}>
          <img height={size * 10} src={BitcoinImg} alt="" />
        </Bitcoin>
      </BChain3>

      <BChain2 $mousePosition={mousePosition}>
        <img style={{ zIndex: 1 }} height={size * 33} src={BChain2Img} alt="" />

        <Ether $mousePosition={mousePosition}>
          <img height={size * 17} src={EtherImg} alt="" />
        </Ether>
      </BChain2>

      <BChain1 $mousePosition={mousePosition}>
        <img style={{ zIndex: 1 }} height={size * 50} src={BChain1Img} alt="" />

        <Doge $mousePosition={mousePosition}>
          <img height={size * 25} src={DogeImg} alt="" />
        </Doge>
      </BChain1>
    </SecondBoxContainer>
  );
}
