import { createSlice, IReduxStore, PayloadAction } from '@reduxjs/toolkit';

const initialState: IReduxStore['metamaskAuth'] = {
  metamaskAuth: { account: undefined, chainId: undefined },
};

const metamaskAuthSlice = createSlice({
  name: 'metamaskAuth',
  initialState,
  reducers: {
    setMetamaskAccount: (state, action: PayloadAction<{ account: string; chainId: string }>) => {
      if (!action.payload) state.metamaskAuth = { account: undefined, chainId: undefined };
      if (action.payload) state.metamaskAuth = action.payload;
    },
  },
});

const { setMetamaskAccount } = metamaskAuthSlice.actions;
export { metamaskAuthSlice, setMetamaskAccount };
