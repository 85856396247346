import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const gamblanceIcon = require('@/assets/gamblanceIcon500x500.png');

const LogoText = styled.span<{ $theme: 'dark' | 'light' }>`
  @media (max-width: 500px) {
    display: none;
  }

  font-size: 28px;
  font-weight: 800;
  font-family: var(--logo-font);
  color: transparent;
  background: ${({ $theme }) =>
    `linear-gradient(110deg, ${
      $theme === 'dark' ? 'var(--color-primary)' : 'var(--color-white)'
    } 27%, var(--color-accent) 56%)`};
  -webkit-background-clip: text;
  background-clip: text;
  display: inline-block;
`;

const LogoWithImageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  img {
    width: 28px;
    height: 28px;
  }
`;

export default function AppLogo({
  resizeble = true,
  theme = 'dark',
}: {
  resizeble?: boolean;
  theme?: 'dark' | 'light';
}) {
  return (
    <Link to={'/'}>
      <LogoWithImageContainer>
        <img src={gamblanceIcon} />
        <LogoText $theme={theme}>GAMBLANCE</LogoText>
      </LogoWithImageContainer>
    </Link>
  );
}
