import React, { useState } from 'react';
import Locks from '@/components/Modality/Locks';
import { DefaultContentContainer } from '@/styles/GlobalStyles';
import styled from 'styled-components';
import FAQ from '@/components/Generics/FAQ';

const LockContainer = styled.div``;

const HelpSessionContainer = styled.div`
  margin-top: 24px;

  p {
    color: var(--color-primary);
  }
  p.title {
    font-weight: 600;
    margin-bottom: 8px;
  }
  p.title:hover {
    cursor: pointer;
  }
`;

export default function Lock() {
  return (
    <DefaultContentContainer>
      <LockContainer>
        <Locks />
        <HelpSessionContainer>
          <div style={{ maxWidth: '350px' }}>
            <FAQ
              questions={[
                {
                  question: 'How does it work?',
                  answer:
                    'This modality allows you to lock a specified amount of money for a set period. Using smart contracts, your funds are securely held and cannot be accessed until the lock duration expires, ensuring peace of mind. Currently, we support locking Arbitrum, Base, and Polygon networks.',
                },
              ]}
            />
          </div>
        </HelpSessionContainer>
      </LockContainer>
    </DefaultContentContainer>
  );
}
