import { ICurrency, INetwork, NETWORKS } from '@/config/NETWORKS';
import useGetTokenBalance, { IGetTokenBalance } from '@/hooks/useGetTokenBalance';
import useGetWalletData, { IGetMainCurrencyBalance, TWalletData } from '@/hooks/useGetWalletData';
import { SDKProvider } from '@metamask/sdk-react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const defaultNetwork = NETWORKS['Base'];
const defaultCurrency = { [defaultNetwork.mainCurrency]: defaultNetwork.currenciesList[defaultNetwork.mainCurrency] };

const WalletContext = createContext<{
  metamaskSDKProvider?: undefined | SDKProvider;
  feedWalletContext: (_sdkProvider: SDKProvider, metamaskAccount?: string, metamaskChainId?: string) => void;

  activeCurrency?: undefined | ICurrency;
  setActiveCurrency?: undefined | React.Dispatch<React.SetStateAction<undefined | ICurrency>>;

  activeCurrencyBalance?:
    | undefined
    | null
    | IGetMainCurrencyBalance['mainCurrencyBalance']
    | IGetTokenBalance['tokenBalance'];
  setActiveCurrencyBalance?: undefined | React.Dispatch<React.SetStateAction<number | undefined>>;

  metamaskAccount?: undefined | string;
  activeNetwork: INetwork;
  refreshBalance?: () => Promise<void>;

  walletData: undefined | null | TWalletData;
  refreshWalletData?: () => Promise<void>;
}>({
  walletData: undefined,
  metamaskAccount: undefined,
  feedWalletContext: () => {},
  activeNetwork: defaultNetwork,
});

export function WalletContextProvider({ children }: { children: JSX.Element }) {
  const { fetchWalletData, walletData, cleanWalletData } = useGetWalletData();
  const { fetchTokenBalance, tokenBalance } = useGetTokenBalance();

  const [metamaskSDKProvider, setMetamaskSDKProvider] = useState<SDKProvider | undefined>(undefined);
  const [metamaskAccount, setMetamaskAccount] = useState<string | undefined>(undefined);

  const [activeCurrencyBalance, setActiveCurrencyBalance] = useState<
    undefined | null | IGetMainCurrencyBalance['mainCurrencyBalance']
  >(undefined);
  const [activeCurrency, setActiveCurrency] = useState<undefined | ICurrency>(defaultCurrency);
  const [activeNetwork, setActiveNetwork] = useState<INetwork>(defaultNetwork);

  const feedWalletContext = (_sdkProvider: SDKProvider, metamaskAccount?: string, metamaskChainId?: string) => {
    if (!metamaskChainId) return;
    const chainIdsList = Object.values(NETWORKS).map((network) => network.chainId);

    if (metamaskChainId === '0xaa36a7') {
      handleChangeNetworkResettingCurrency('Sepolia');
    }
    if (metamaskChainId === '0x2105') {
      handleChangeNetworkResettingCurrency('Base');
    }
    if (metamaskChainId === '0xa4b1') {
      handleChangeNetworkResettingCurrency('Arbitrum');
    }
    if (metamaskChainId === '0x89') {
      handleChangeNetworkResettingCurrency('Polygon');
    }

    if (!chainIdsList.includes(metamaskChainId)) {
      toast.error('Network not supported yet...');
      return;
    }

    setMetamaskSDKProvider(_sdkProvider);
    setMetamaskAccount(metamaskAccount);
  };

  const handleChangeNetworkResettingCurrency = (networkName: string) => {
    const network = NETWORKS[networkName];

    setActiveNetwork(network);
    setActiveCurrency({ [network.mainCurrency]: network.currenciesList[network.mainCurrency] });
  };

  const _refreshBalance = async () => {
    setActiveCurrencyBalance(undefined);

    if (activeCurrency && !Object.values(activeCurrency)[0].address) {
      setActiveCurrencyBalance(walletData?.mainCurrencyBalance);
      return;
    }
    if (!activeCurrency || !activeNetwork || !metamaskAccount || !Object.values(activeCurrency)[0].address) return;
    fetchTokenBalance({
      walletAddress: metamaskAccount,
      chainId: activeNetwork.chainId,
      tokenAddress: Object.values(activeCurrency)[0].address!,
    });
  };

  const refreshWalletData = async () => {
    if (metamaskAccount && activeNetwork) {
      await fetchWalletData({ walletAddress: metamaskAccount, chainId: activeNetwork.chainId });
      _refreshBalance();
    }
  };

  useEffect(() => {
    setActiveCurrencyBalance(tokenBalance);
  }, [tokenBalance]);

  useEffect(() => {
    _refreshBalance();
  }, [activeCurrency, activeNetwork, metamaskAccount, walletData]);

  useEffect(() => {
    refreshWalletData();
  }, [metamaskAccount, activeNetwork]);

  useEffect(() => {
    if (!metamaskAccount) {
      setActiveCurrencyBalance(undefined);
      setActiveCurrency(undefined);
      cleanWalletData();
    }
  }, [metamaskAccount]);

  return (
    <WalletContext.Provider
      value={{
        metamaskSDKProvider,
        metamaskAccount,
        feedWalletContext,

        activeCurrency,
        setActiveCurrency,

        activeNetwork,
        activeCurrencyBalance,

        walletData,
        refreshWalletData,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
}

export function useWalletContext() {
  return useContext(WalletContext);
}
