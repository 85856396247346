import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { styled } from 'styled-components';
import { FaLock } from 'react-icons/fa6';
import { GoGoal } from 'react-icons/go';

import { useScreenConfig } from '../../../contexts/ScreenConfigContext';
import AppLogo from '../AppLogo';

import { useSDK } from '@metamask/sdk-react';
import { useWalletContext } from '@/contexts/WalletContext';

import useApplyMetamaskInfo from '@/hooks/useApplyMetamaskInfo';
import RightBox from './RightBox';
import BottomMenu from './BottomMenu';

const HeaderContainer = styled.div`
  user-select: none;
  height: var(--header-height);
  top: 0;
  left: 0;
  position: fixed;
  z-index: 10;
  width: 100%;
  background: var(--color-header);
  transition: box-shadow 0.3s ease;
`;

const HeaderMenusContainer = styled.div`
  max-width: var(--page-mx-width);
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
`;

const MenuItemsContainer = styled.div<{ $screenWidth: number }>`
  height: 100%;
  display: ${({ $screenWidth }) => ($screenWidth > 1000 ? 'flex' : 'none')};
  gap: 0.5rem;
  justify-content: space-between;
  text-transform: uppercase;
  transition: all 0.25s ease-in-out;
`;

const HeaderMenuItem = styled.div<{ $isActive: boolean }>`
  cursor: pointer;
  display: flex;
  gap: 12px;
  height: var(--elements-height);
  align-items: center;
  white-space: nowrap;
  transition: all 0.15s;
  background-color: ${({ $isActive }) => ($isActive ? 'var(--color-accent)' : 'none')};
  padding: 8px 30px;
  border-radius: var(--default-br);

  span {
    font-weight: 800;
    font-size: 14px;
    color: var(--color-primary);
  }
  svg {
    fill: ${({ $isActive }) => ($isActive ? 'var(--color-primary)' : 'var(--color-primary)')};
  }

  &:hover {
    background-color: var(--color-accent);

    span {
      color: var(--color-primary);
    }
    h4 {
      color: var(--color-primary);
    }
    svg {
      fill: var(--color-black);
    }
  }
`;

const Box1 = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
`;

export interface IHeaderMenuItems {
  [menu: string]: { path: string; icon?: React.ReactElement };
}

export const menuItems: IHeaderMenuItems = {
  Lock: { path: '/lock', icon: <FaLock /> },
  Goal: { path: '/goal', icon: <GoGoal /> },
};

const Header = () => {
  const sdkState = useSDK();

  const { applyMetamaskInfo } = useApplyMetamaskInfo();
  const { width } = useScreenConfig();
  const location = useLocation();

  useEffect(() => {
    applyMetamaskInfo(sdkState);
  }, [sdkState.chainId, sdkState.account, sdkState.sdk]);

  return (
    <>
      <HeaderContainer>
        <HeaderMenusContainer>
          <Box1>
            <AppLogo />

            <MenuItemsContainer $screenWidth={width}>
              {Object.entries(menuItems).map(([item, { path, icon }], i) => (
                <Link to={path} key={i}>
                  <HeaderMenuItem $isActive={location.pathname === path}>
                    {icon && icon}
                    <span>{item}</span>
                  </HeaderMenuItem>
                </Link>
              ))}
            </MenuItemsContainer>
          </Box1>

          <RightBox />
        </HeaderMenusContainer>
      </HeaderContainer>

      {width < 1000 && <BottomMenu />}
    </>
  );
};

export default React.memo(Header);
