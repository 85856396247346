import React, { useState } from 'react';
import styled from 'styled-components';
import { IoIosArrowForward } from 'react-icons/io';
import { IoIosArrowDown } from 'react-icons/io';

const FAQContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Question = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-weight: 500;
  }

  svg {
    min-width: 18px;
    max-width: 18px;
    height: 18px;
  }
`;

const Answer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-weight: 400;
    color: var(--color-secondary);
    font-size: 16px;
  }
`;

export default function FAQ({ questions }: { questions: { question: string; answer: string }[] }) {
  const [questionsOpened, setQuestionsOpened] = useState<number[]>([]);

  const handleClick = (index: number) => {
    if (!questionsOpened.includes(index)) setQuestionsOpened((prev) => [...prev, index]);
    if (questionsOpened.includes(index)) setQuestionsOpened((prev) => prev.filter((number) => number !== index));
  };

  return (
    <FAQContainer>
      {questions.map((question, index) => (
        <Item key={index}>
          <Question key={`question-${index}`} onClick={() => handleClick(index)}>
            <p>{question.question}</p>

            {!questionsOpened.includes(index) && <IoIosArrowForward />}
            {questionsOpened.includes(index) && <IoIosArrowDown />}
          </Question>

          {questionsOpened.includes(index) && (
            <Answer key={`answer-${index}`}>
              <p>{question.answer}</p>
            </Answer>
          )}
        </Item>
      ))}
    </FAQContainer>
  );
}
