import React, { ReactNode } from 'react';
import styled from 'styled-components';

const ContentTemplateContainer = styled.div`
  width: 100%;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-direction: column;
  border-radius: var(--default-br);
  padding: var(--default-pdn);

  h4 {
    color: var(--color-white);
  }
`;

const Header = styled.div`
  width: 100%;
`;

const Body = styled.div`
  width: 100%;
`;

interface IModalContentTemplate1 {
  title: string;
  children: ReactNode;
}

export default function ModalContentTemplate1({ title, children }: IModalContentTemplate1) {
  return (
    <ContentTemplateContainer>
      <Header>
        <h4>{title}</h4>
      </Header>

      <Body>{children}</Body>
    </ContentTemplateContainer>
  );
}
