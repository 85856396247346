import React, { ReactNode, useState } from 'react';
import { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';

const DefaultContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export interface IForm {
  axiosCallHook: (payload: any) => any;
  children: ReactNode;
  rhfConfig: UseFormReturn<FieldValues, any, undefined>;
}

export default function Form(props: IForm) {
  const { axiosCallHook, children, rhfConfig } = props;

  const { handleSubmit } = rhfConfig;

  const onSubmitHandler: SubmitHandler<FieldValues> = async (info) => {
    try {
      await axiosCallHook({ ...info });
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} noValidate>
      <DefaultContainer>{children}</DefaultContainer>
    </form>
  );
}
