import URLS from '@/config/URLs';
import MyAxiosServiceInstance from '@/services/MyAxiosService';
import React from 'react';
import { toast } from 'react-toastify';

interface IGetTokenExistanceResponse {
  tokenExistance: {
    details: {
      symbol: string;
    };
  } | null;
}

interface IFetchGetTokenExistancePayload {
  walletAddress: string;
  chainId: string;
  tokenAddress: string;
}

export default function useCheckTokenExistance() {
  const fetchTokenExistance = async (payload: IFetchGetTokenExistancePayload) => {
    try {
      const fetch = await MyAxiosServiceInstance.request<IGetTokenExistanceResponse>({
        requestConfig: {
          url: URLS.ENDPOINTS.WALLET.TOKEN_EXISTANCE,
          method: 'post',
          data: payload,
        },
      });

      if (fetch.data) {
        return fetch.data;
      }
    } catch (err) {
      toast.error('Error getting token existance');
    }
  };

  return { fetchTokenExistance };
}
