import { css } from 'styled-components';

export const InputCommonStyle = css`
  border: none;

  width: 100%;
  height: var(--elements-height);

  color: var(--color-accent);
  font-family: var(--font-primary);
  font-size: 16px;
  font-weight: 700;

  background-color: var(--color-lightprimary);
  padding: 0px 12px;
  border-radius: var(--default-br);

  color-scheme: dark;
`;

export const CommonStyles = css`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;

  input {
    ${InputCommonStyle}
  }

  input:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  input:focus {
    outline: none;
  }

  span {
    font-size: 14px;
    font-weight: 700;
    color: var(--color-white);
    text-transform: uppercase;
  }

  label {
    width: 100%;
  }
`;
