import { InputCommonStyle } from '@/components/Form/Input/CommonStyles';
import styled, { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    --font-primary: 'Montserrat', sans-serif;
    --header-height: 60px;
    --elements-height: 40px;
    --page-mx-width: 1350px;

    --color-header: #ffffff; /* Cinza Claro */
    --color-background: #edeff4; /* Cinza Claro */

    --color-primary: #262941; /* Cinza Azul Escuro */
    --color-lightprimary: #3d405f; /* Cinza Azul Escuro */
    --color-secondary: #4A4E6B; /* Azul Claro */
    --color-accent: #2EC4B6; /* Verde Esmeralda */
    --color-text: #1A1A1A; /* Preto Suave */
    --color-error: #cb2626; /* Vermelho Suave */

    --color-primary-btn: var(--color-accent);

    --color-black: #0A0908;
    --color-grey: #a5a5a8;
    --color-middlegrey: #dddddd;
    --color-lightgrey: #e6e6e6;
    --color-whitegrey: #f0f0f0;
    --color-white: #ffffff;

    --color-oceanblue: #39649c;
    --color-darkblue: #0e76ff;
    --color-blue: #2985ff;
    --color-grafitti: #1b1b1b;
    --color-brown: #4b4237;
    --color-lightblue: #579efc;
    --color-red: #bd3434;
    --color-lightgreen: #91d58d;
    --color-green: #2b9e35;
    --color-darkgreen: #459f3f;
    --color-yellow: #d0b320;
    --color-orange: #e9a220;

    --default-br: 12px; // Border-radius
    --default-bshadow: 0 2px 3px rgba(0,0,0,.078);
    --default-btn-mt: 8px;
    --default-gradient: linear-gradient(153deg, #1a1a1a 18%, rgba(74,74,74,1) 100%);
    --default-pdn: 30px; //

    @media (max-width: 768px) {
      --default-pdn: 20px; //
    }
  }


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--font-primary);
  }


  body {
    background-color: var(--color-background);
    background-image: linear-gradient(0deg, white, 30%, black, 100%);
    color: var(--color-text);
    min-height: 1200px;

    .Toastify__toast {
      font-family: var(--font-primary);
      font-weight: 600;
    }

    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-track {
      background: var(--color-primary);
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: var(--color-secondary);
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  }

  body[data-theme='dark'] {
    --color-background: #0d131f;
    --color-header: #2B2D42;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: var(--color-primary);
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 800;
    text-transform: uppercase;
    line-height: 1.2;
    color: var(--color-primary);
  }

  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.2rem;
  }

  h6 {
    font-size: 1rem;
  }

  p,span {
    font-size: 18px;
    color: var(--color-primary);
  }

  input {
    ${InputCommonStyle}
  }

/* Tablet Breakpoint */
@media (max-width: 768px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.2rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.25rem;
  }

  h5 {
    font-size: 1rem;
  }

  h6 {
    font-size: 0.875rem;
  }
}

/* Mobile Breakpoint */
@media (max-width: 480px) {
  h1 {
    font-size: 2.25rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.2rem;
  }

  h5 {
    font-size: 1rem;
  }

  h6 {
    font-size: 0.875rem;
  }

  p {
    font-size: 16px;
  }
}

/* Small Mobile Breakpoint */
@media (max-width: 360px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1rem;
  }

  h5 {
    font-size: 0.875rem;
  }

  h6 {
    font-size: 0.75rem;
  }

  p {
    font-size: 0.875rem;
  }
}
`;

export const TruncatedText = styled.div`
  display: flex;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    justify-content: center;
    align-items: center;
    display: inline-block; /* ou -webkit-box; */
    height: 100%;
    max-width: 100%; /* ou qualquer largura desejada */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const Body = styled.div`
  margin: 0 auto;
  padding: var(--header-height) 0 0 0;

  @media (max-width: 1000px) {
    padding: var(--header-height) 0 var(--header-height) 0;
  }
`;

export const SectionContentContainer = styled.div`
  width: 100%;
  max-width: var(--page-mx-width);
  padding: 120px 0.75rem;
  margin: 0 auto;

  @media (max-width: 1000px) {
    padding: 70px 0.75rem;
  }
`;

export const DefaultContentContainer = styled.div`
  width: 100%;
  max-width: var(--page-mx-width);
  padding: 40px 0.75rem;
  margin: 0 auto;

  @media (max-width: 1000px) {
    padding: 20px 0.75rem;
  }
`;

export const HRBar = styled.div`
  width: 100%;
  height: 1px;
  background: var(--color-secondary);
  opacity: 0.3;
  border-radius: var(--default-br);
`;

export const DefaultBox = styled.div`
  height: var(--elements-height);
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-radius: var(--default-br);
  background-color: var(--color-primary);
  padding: 0.5rem 1rem;
`;

export const SmallInfoMessage = styled.span`
  display: flex;
  gap: 0.25rem;
  color: var(--color-grey) !important;
  font-weight: 600 !important;
  font-size: 12px !important;
`;

export const MediumInfoMessage = styled.span`
  color: var(--color-grey);
  font-weight: 600;
  font-size: 14px;
`;
