import { configureStore } from '@reduxjs/toolkit';
import { metamaskAuthSlice } from './slices/metamaskAuthSlice';

interface IMetamaskAuthProps {
  account?: string;
  chainId?: string;
}

export interface IReduxStore {
  metamaskAuth: { metamaskAuth: IMetamaskAuthProps };
}

const reduxStore = configureStore({ reducer: { metamaskAuth: metamaskAuthSlice.reducer } });

export default reduxStore;
