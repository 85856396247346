import { SectionContentContainer } from '@/styles/GlobalStyles';
import React from 'react';
import styled from 'styled-components';
import AppLogo from './AppLogo';
import { FaLinkedinIn, FaDiscord } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const FooterContainer = styled.div`
  background-color: var(--color-primary);
  min-height: 400px;
`;

const Boxes = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;
  justify-content: space-between;

  &::after {
    content: '';
  }

  @media (max-width: 720px) {
    flex-wrap: wrap-reverse;
    gap: 4rem;

    &::after {
      display: none;
    }
  }
`;

const Box1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    color: var(--color-white);
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap;
  }

  @media (max-width: 720px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

const Box2 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10rem;

  span {
    color: var(--color-white);
    font-weight: 700;
    font-size: 16px;
    white-space: nowrap;
  }

  @media (max-width: 800px) {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;
  }
`;

const Menu = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  span:hover {
    color: var(--color-accent);
  }
`;

const Socials = styled.div`
  display: flex;
  gap: 1rem;
`;

const Social = styled.div`
  width: 40px;
  height: 40px;
  background-color: var(--color-secondary);
  border-radius: var(--default-br);
  display: flex;
  justify-content: center;

  svg {
    width: 20px;
    height: 100%;
    color: var(--color-white);
  }
`;

const RightsReserved = styled.span`
  background-color: var(--color-primary);
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--color-secondary);
  font-weight: 600;
  font-size: 16px;
  padding: 0 20px 40px 20px;
  text-align: center;
`;

interface IMenu {
  [label: string]: { path: string; targetBlank?: boolean };
}

export default function Footer() {
  const MainMenus: IMenu = {
    Home: { path: '/' },
    'Lock Crypto': { path: '/lock' },
    'Set a Goal': { path: '/goal' },
  };

  const InfoMenus: IMenu = {
    'Terms of Service': { path: '/tos' },
    Article: { path: 'https://jlenon.com/project/gamblance', targetBlank: true },
    Help: { path: '/help' },
  };

  return (
    <FooterContainer>
      <SectionContentContainer>
        <Boxes>
          <Box1>
            <AppLogo theme="light" />
            <p>Less Gambling, More Finance</p>

            <div></div>

            <div></div>

            <Socials>
              {/* <a href="/" target="_blank"> */}
              <Social>
                <FaDiscord />
              </Social>
              {/* </a> */}

              <a href="https://www.linkedin.com/in/joselenon" target="_blank">
                <Social>
                  <FaLinkedinIn />
                </Social>
              </a>
            </Socials>
          </Box1>

          <Box2>
            <Menu>
              {Object.keys(MainMenus).map((key: keyof IMenu, index) => {
                const menu = MainMenus[key];
                return (
                  <Link key={index} to={menu.path} target={menu.targetBlank ? '_blank' : '_self'}>
                    <span>{key}</span>
                  </Link>
                );
              })}
            </Menu>

            <Menu>
              {Object.keys(InfoMenus).map((key: keyof IMenu, index) => {
                const menu = InfoMenus[key];
                return (
                  <Link key={index} to={menu.path} target={menu.targetBlank ? '_blank' : '_self'}>
                    <span>{key}</span>
                  </Link>
                );
              })}
            </Menu>
          </Box2>
        </Boxes>
      </SectionContentContainer>

      <RightsReserved>© 2024 Gamblance.com | All Rights Reserved</RightsReserved>
    </FooterContainer>
  );
}
