import { css } from 'styled-components';

export const CommonStyles = css`
  cursor: pointer;
  border-radius: var(--default-br);
  min-width: var(--elements-height);
  height: var(--elements-height);
  padding: 0px calc(var(--default-pdn) - 5px);
  width: 100%;
  white-space: nowrap;
  border: none;

  span {
    font-family: var(--font-primary);
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
  }

  &:hover {
    filter: brightness(0.9);
  }
  &:active {
    filter: brightness(0.75);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
`;
