import React, { ButtonHTMLAttributes } from 'react';
import { styled } from 'styled-components';
import { CommonStyles } from './CommonStyles';
import LoadingGif from './LoadingGif';

const ButtonStyle = styled.button<{
  $color?: string;
  $textColor?: string;
}>`
  ${CommonStyles}

  background: ${({ $color }) => ($color ? $color : 'var(--color-primary)')};

  svg {
    color: var(--color-white);
    vertical-align: middle;
    display: inline-block;
  }

  span {
    color: ${({ $textColor }) => ($textColor ? $textColor : 'var(--color-white)')};
  }
`;

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`;

export interface IButton {
  element?: React.ReactElement;
  label?: string;
  color?: string;
  textColor?: string;
  attributes?: ButtonHTMLAttributes<HTMLButtonElement>;
  customStyle?: React.CSSProperties;
}

export default function PrimaryButton({ element, label, color, textColor, attributes, customStyle }: IButton) {
  return (
    <ButtonStyle
      type={attributes?.type ? attributes.type : 'button'}
      {...attributes}
      style={customStyle}
      $color={color}
      $textColor={textColor}
    >
      <Container>
        {!attributes?.disabled && element}
        {!attributes?.disabled && <span>{label}</span>}

        {attributes?.disabled && <LoadingGif />}
      </Container>
    </ButtonStyle>
  );
}
