import { SectionContentContainer } from '@/styles/GlobalStyles';
import React from 'react';
import styled from 'styled-components';

const moneyImg = require('./assets/money.png');

const Section3Wrapper = styled.div`
  background-color: var(--color-primary);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${moneyImg});
    background-size: cover;
    background-position: center;
    opacity: 0.05;
  }

  h2 {
    text-transform: none;
    text-align: center;
  }
`;

const Boxes = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 720px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const BoxContainer = styled.div`
  margin: 50px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  h1 {
    color: var(--color-accent);
    white-space: nowrap;
  }

  span {
    color: var(--color-white);
    font-size: 18px;
    text-align: center;
  }

  @media (max-width: 720px) {
    margin: 30px;
  }
`;

const Box = ({ stat, label }: { stat: string; label: string }) => (
  <BoxContainer>
    <h1>{stat}</h1>
    <span>{label}</span>
  </BoxContainer>
);

export default function Section3() {
  return (
    <Section3Wrapper>
      <SectionContentContainer>
        <Boxes>
          <Box stat="150$+" label="already stored in blockchain" />
          <Box stat="5+" label="people already taking care about their finances" />
          <Box stat="30%" label="of average appreciation between locks" />
        </Boxes>
      </SectionContentContainer>
    </Section3Wrapper>
  );
}
