import {
  isCpfValid,
  isDateValid,
  isEmailValid,
  isFullNameValid,
  isPasswordsValid,
  isPasswordValid,
  isValueToLockValid,
} from '@/utils/isValid';
import DefaultInput, { ICreateDefaultInput } from './DefaultInput';
import { InputHTMLAttributes } from 'react';

export default function renderGenericInput({
  type,
  rhfConfig,
  extraInformation,
  attributes,
}: {
  type:
    | 'email'
    | 'username'
    | 'fullName'
    | 'cpf'
    | 'cellphone'
    | 'password'
    | 'cpassword'
    | 'valueToSend'
    | 'unlockDate'
    | 'currency';
  rhfConfig: ICreateDefaultInput['rhfConfig'];
  extraInformation?: any;
  attributes?: InputHTMLAttributes<HTMLInputElement>;
}) {
  switch (type) {
    case 'email':
      return (
        <DefaultInput
          label="E-mail"
          id="email"
          attributes={{
            ...attributes,
            type: 'text',
            required: true,
            placeholder: 'Digite seu e-mail',
            autoComplete: 'email',
          }}
          rhfConfig={rhfConfig}
          validationFn={(value: string) => isEmailValid(value)}
        />
      );
    case 'fullName':
      return (
        <DefaultInput
          label="Nome Completo"
          id="fullName"
          attributes={{ ...attributes, type: 'text', required: true, placeholder: 'Digite seu nome completo' }}
          rhfConfig={rhfConfig}
          validationFn={(value: string) => isFullNameValid(value)}
        />
      );
    case 'cpf':
      return (
        <DefaultInput
          label="CPF"
          id="cpf"
          attributes={{ ...attributes, type: 'text', required: true, placeholder: 'Digite seu CPF' }}
          rhfConfig={rhfConfig}
          validationFn={(value: string) => isCpfValid(value)}
        />
      );
    case 'cellphone':
      return (
        <DefaultInput
          label="Celular"
          id="cellphone"
          attributes={{ ...attributes, type: 'text', required: true, placeholder: 'Digite seu celular' }}
          rhfConfig={rhfConfig}
          validationFn={(value: string) => isPasswordValid(value)}
        />
      );
    case 'password':
      return (
        <DefaultInput
          label="Senha"
          id="password"
          attributes={{ ...attributes, type: 'password', required: true, placeholder: 'Digite sua senha' }}
          rhfConfig={rhfConfig}
          validationFn={(value: string) => isPasswordValid(value)}
        />
      );
    case 'cpassword':
      return (
        <DefaultInput
          label="Confirmar Senha"
          id="cpassword"
          attributes={{ ...attributes, type: 'password', required: true, placeholder: 'Confirme sua senha' }}
          rhfConfig={rhfConfig}
          validationFn={(value: string, getValues) =>
            isPasswordsValid({ password: getValues().password, cpassword: value })
          }
        />
      );

    /* Contract Interaction Stuff */
    case 'valueToSend':
      return (
        <DefaultInput
          label={`Value to lock`}
          id="valueToLock"
          attributes={{ ...attributes, type: 'number', required: true, placeholder: '0.0' }}
          rhfConfig={rhfConfig}
          validationFn={(value: number) =>
            isValueToLockValid({ valueToLock: value, extraInformation: extraInformation })
          }
        />
      );

    /* Contract Interaction Stuff */
    case 'unlockDate':
      return (
        <DefaultInput
          label="Unlock date"
          id="unlockDate"
          attributes={{ ...attributes, type: 'datetime-local', required: true, placeholder: '0.0' }}
          rhfConfig={rhfConfig}
          validationFn={(value: string) => isDateValid(value)}
        />
      );

    /* Contract Interaction Stuff */
    case 'currency':
      return (
        <DefaultInput
          id="currency"
          attributes={{ type: 'text', required: true, placeholder: 'Search token or paste address', ...attributes }}
          rhfConfig={rhfConfig}
          validationFn={() => {
            return { valid: true, errorMsg: '' };
          }}
        />
      );
  }
}
