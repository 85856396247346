import React, { useEffect } from 'react';
import SecondaryButton from '@/components/Buttons/SecondaryButton';
import Form from '@/components/Form';
import renderGenericInput from '@/components/Form/Input/GenericsInputs';
import { useWalletContext } from '@/contexts/WalletContext';
import useLockMainValue from '@/hooks/useLockMainValue';
import { HRBar, MediumInfoMessage, SmallInfoMessage } from '@/styles/GlobalStyles';
import { useForm } from 'react-hook-form';
import { styled } from 'styled-components';
import { useScreenConfig } from '@/contexts/ScreenConfigContext';
import NetworkAndCurrencySelect from './NetworkAndCurrencySelect';
import ConnectedWallet from '@/components/ConnectedWallet';
import useLockTokens from '@/hooks/useLockTokens';
import { toast } from 'react-toastify';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import BlurredLoadDiv from '@/components/Generics/BlurredLoadDiv';
import useApproveTokens from '@/hooks/useApproveTokens';

const LockBoxContainer = styled.div<{ $isMobile: boolean }>`
  max-width: 350px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: var(--default-pdn);
  border-radius: var(--default-br);
  background-color: var(--color-primary);
  box-shadow: var(--default-bshadow);

  @media (max-width: 720px) {
    max-width: none;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  p {
    color: var(--color-grey);
  }
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const ValueToLockContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Box1 = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
`;

const Title = styled.h3`
  color: var(--color-white);
`;

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 10px;
`;

const WarningMessage = styled.span`
  color: var(--color-red);
  font-weight: 600;
  font-size: 12px;
`;

function formatTimestampToDateTimeLocal(timestamp: number) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export default function LockBox() {
  const { isMobile } = useScreenConfig();
  const { activeCurrency, activeCurrencyBalance, setActiveCurrencyBalance } = useWalletContext();
  const { lockMainValue } = useLockMainValue();
  const { lockTokens } = useLockTokens();
  const { approveTokens } = useApproveTokens();

  const rhfConfig = useForm();
  const { getValues, setValue, resetField, formState } = rhfConfig;

  const onSubmitForm = async (values: any) => {
    const activeCurrencyInfo = activeCurrency ? Object.values(activeCurrency)[0] : undefined;
    if (!activeCurrencyInfo) {
      toast.error('You should set a currency');
      return;
    }

    const unlockAmountInSeconds = Math.round(new Date(values.unlockDate).getTime() / 1000 - Date.now() / 1000);
    const value = getValues().valueToLock;

    if (!activeCurrencyInfo.address) {
      await lockMainValue(unlockAmountInSeconds, value);
      return;
    }

    const approvedTokens = await approveTokens(value);
    if (approvedTokens) {
      await lockTokens(unlockAmountInSeconds, approvedTokens.formattedAmount);
      setActiveCurrencyBalance && setActiveCurrencyBalance((prev) => (prev ? prev - value : prev));
    }
  };

  useEffect(() => {
    setValue('unlockDate', formatTimestampToDateTimeLocal(Date.now()));
  }, []);

  const addDaysToDate = (days: number) => {
    const currentDate = new Date(getValues().unlockDate);
    currentDate.setDate(currentDate.getDate() + days);
    setValue('unlockDate', formatTimestampToDateTimeLocal(currentDate.getTime()));
  };

  const formattedDate = formatTimestampToDateTimeLocal(getValues().unlockDate);

  return (
    <LockBoxContainer $isMobile={isMobile}>
      <HeaderContainer>
        <Title>Locker</Title>
        <MediumInfoMessage>Lock up your crypto for a certain period of time</MediumInfoMessage>
      </HeaderContainer>

      <HRBar />

      <ConnectedWallet />
      <div></div>

      <BodyContainer>
        <div style={{ width: '100%' }}>
          <Form rhfConfig={rhfConfig} axiosCallHook={onSubmitForm}>
            <NetworkAndCurrencySelect />

            <ValueToLockContainer>
              <Box1>
                <div style={{ width: '75%' }}>
                  {renderGenericInput({
                    type: 'valueToSend',
                    rhfConfig,
                    extraInformation: { balance: activeCurrencyBalance },
                  })}
                </div>
                <div style={{ width: '25%' }}>
                  <PrimaryButton
                    color="var(--color-white)"
                    textColor="var(--color-primary)"
                    label="MAX"
                    attributes={{
                      type: 'button',
                      onClick: () => {
                        resetField('valueToLock');
                        setValue('valueToLock', activeCurrencyBalance?.value.toFixed(9));
                      },
                    }}
                  />
                </div>
              </Box1>

              <SmallInfoMessage>
                Available:
                <BlurredLoadDiv isLoading={activeCurrencyBalance === undefined}>
                  <>{activeCurrencyBalance ? activeCurrencyBalance.value.toFixed(9) : '0.000000000'}</>
                </BlurredLoadDiv>
              </SmallInfoMessage>
            </ValueToLockContainer>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              {renderGenericInput({
                attributes: { defaultValue: formattedDate },
                type: 'unlockDate',
                rhfConfig,
              })}

              <div style={{ display: 'flex', gap: '0.5rem' }}>
                <PrimaryButton
                  color="var(--color-white)"
                  textColor="var(--color-primary)"
                  label="+7 Days"
                  attributes={{ type: 'button', onClick: () => addDaysToDate(7) }}
                />
                <PrimaryButton
                  color="var(--color-white)"
                  textColor="var(--color-primary)"
                  label="+30 Days"
                  attributes={{ type: 'button', onClick: () => addDaysToDate(30) }}
                />
                <PrimaryButton
                  color="var(--color-white)"
                  textColor="var(--color-primary)"
                  label="+90 Days"
                  attributes={{ type: 'button', onClick: () => addDaysToDate(90) }}
                />
              </div>
            </div>

            <div>
              <MessagesContainer>
                <SmallInfoMessage>Service Fee: 2.5%</SmallInfoMessage>
                <WarningMessage>Attention: Locks are absolutely unreversible</WarningMessage>
              </MessagesContainer>

              <PrimaryButton
                label="LOCK"
                color="var(--color-accent)"
                attributes={{ type: 'submit', disabled: formState.isSubmitting }}
              />
            </div>
          </Form>
        </div>
      </BodyContainer>
    </LockBoxContainer>
  );
}
